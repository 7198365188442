
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import context from "@cloudfun/core";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/assets/css/wysiwyg.css";
import { CustomFigureAttributes } from "@/global-components/ckeditor/custom";

export default defineComponent({
  setup() {
    const application = context.root;
    const configuration = reactive({
      PortalDefaultTitle: "",
      PortalDefaultMetaKeywords: "",
      PortalDefaultMetaDescription: "",
      ServicePhone: "",
      CompanyFax: "",
      CompanyAddress: "",
      ServiceEmail: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      LINE: "",
      TrackingCode: "",
      AdminTitle: "",
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin, CustomFigureAttributes],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold custom-big" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold custom-default" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      fontSize: {
        options: [
          {
            title: "特小",
            model: "custom-tiny-tool",
            view: { name: "span", classes: "custom-tiny" },
          },
          {
            title: "小",
            model: "custom-small-tool",
            view: { name: "span", classes: "custom-small" },
          },
          {
            title: "預設",
            model: "custom-default-tool",
            view: { name: "span", classes: "custom-default" },
          },
          {
            title: "大",
            model: "custom-big-tool",
            view: { name: "span", classes: "custom-big" },
          },
          {
            title: "特大",
            model: "custom-huge-tool",
            view: { name: "span", classes: "custom-huge" },
          },
        ],
      },
    };

    const rules = {
      PortalDefaultTitle: { required },
      PortalDefaultMetaKeywords: { required },
      PortalDefaultMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      Facebook: { url },
      Instagram: { url },
      Twitter: { url },
      LINE: { url },
      TrackingCode: {},
      AdminTitle: { required },
    };

    const form = reactive({
      Content: "",
    });

    const save = () => {
      application?.model.dispatch("about/update", form).then(
        () => {
          // success
          context.send("info", {
            subject: "更新成功",
            content: "護照與簽證辦理已更新",
          });
        },
        (failure) => {
          context.send("error", {
            subject: "更新失敗",
            content: failure.message,
          });
        }
      );
    };

    onMounted(() => {
      setTimeout(() => {
        application?.model.dispatch("about/find", "Visa").then((value) => {
          Object.assign(form, value);
        });
      }, 0);
    });

    return {
      classicEditor,
      simpleEditorConfig,
      configuration,
      form,
      save,
    };
  },
});
